import './App.css';
import Navbar from "./components/Navbar";
import Imagegen from "./components/Imagegen";

const App = () => {

  return (
    <div className="App">
      <Navbar />
      <header className="App-header">
        <h1>Good Fucking Luck.</h1>
      </header>
      <Imagegen />
    </div>
  );
}

export default App;
