import React, { useState } from "react";

const getImages = async (query) => {
  const url = "https://serverless.the-hub.workers.dev/image-generate";

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({ query }),
    headers: { "Content-type": "application/json" },
  });

  /*returns promise*/
  return response.json();
};

const Imagegen = () => {
  const [query, setQuery] = useState("");
  const [images, setImages] = useState([]);

  const search = async () => {
    /*resolve that promise*/
    const results = await getImages(query);
    setImages(results);
  };

  const updateQuery = (evt) => setQuery(evt.target.value);

  return (
    <div className="Imagegen">
      <div className="form">
        <input
          id="query"
          type="text"
          onChange={updateQuery}
          placeholder="search that shit"
        />
        <button onClick={search}>Search</button>
      </div>

      <div className="image-results">
        {images.map((image) => (
          <a key={image.id} href={image.link} target="_blank" rel="noreferrer">
            <img src={image.image} alt="legends" />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Imagegen;
